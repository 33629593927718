.base-nav {
    position: absolute;
    top: 50%;
    z-index: 2000;
    transform: translateY(-50%);

    li {
        display: block;
        margin-bottom: 10px;
        padding: 15px 15px 15px 10px;
        @include border-radius( 0 20px 20px 0);
        background-color: $orange;
        font-size: 22px;
        color: $white;
        border: 5px solid $white;
        border-left: none;
        cursor: pointer;
		text-transform: uppercase;

        &:last-child {
            margin-bottom: 0;
        }

        &.show {
            box-shadow: 0 0 25px 0 fade-out($textcolor, 0.5);
            background-color: $orange-dark;
        }
        &:hover {
            box-shadow: 0 0 15px 0 fade-out($textcolor, 0.5);
        }
    }

}

.parameters-toggle {
    position: absolute;
    z-index: 2000;
    top: 20%;
    right: 0;
    padding: 30px 10px 30px 15px;
    @include border-radius( 20px 0 0 20px);
    background-color: $red;
    font-size: 30px;
    color: $white;
    border: 5px solid $white;
    border-right: none;
    cursor: pointer;

    &.active {
        box-shadow: 0 0 25px 0 fade-out($textcolor, 0.5);
        background-color: darken($red, 10%);
    }

    &:hover {
        box-shadow: 0 0 15px 0 fade-out($textcolor, 0.5);
    }
}

.uitleg-toggle {
    position: absolute;
    z-index: 2000;
    bottom: 20%;
    right: 0;
    padding: 30px 10px 30px 15px;
    @include border-radius( 20px 0 0 20px);
    background-color: $orange-dark;
    font-size: 30px;
    color: $white;
    border: 5px solid $white;
    border-right: none;
    cursor: pointer;

    &.active {
        box-shadow: 0 0 25px 0 fade-out($textcolor, 0.5);
        z-index: 3500;
    }

    &:hover {
        box-shadow: 0 0 15px 0 fade-out($textcolor, 0.5);
    }
}