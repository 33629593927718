
h1,
h2,
h3,
h4,
h5,
h6 {
    color: $black;
    font-family: $titlefont;
    font-weight: 700;
    display: inline-block;
}

h1 {
    font-size: 28px;
    margin: 0;
}

h2 {
    font-size: 20px;
    margin: 0;
}

h3 {
    font-size: 28px;
    margin: 0;
}

h4 {
    font-size: 14px;
    margin: 0;
    text-transform: uppercase;
}

h5 {
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 8px 0;
}

h6 {
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 24px 0;
}

p {
    margin: 0 0 8px 0;

    &.blue {
        color: $orange;
    }
}

strong {
    font-weight: bold;
}

.blue {
    color: $orange;
    font-weight: 700;
}

.strong {
    font-weight: 700;
}

a {
    color: $linkcolor;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
        color: darken($linkcolor, 5%);
        text-decoration: underline;
    }

}

blockquote {
    border-left: $primarycolor 5px solid;
    padding: 10px 0px 10px 20px;
    margin: 20px 15px 20px 30px;

    p {
        color: lighten($black, 40%);
        margin: 0px;
    }
}

.main-content {
    position: relative;
}