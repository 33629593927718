.traps3 {
	top: 90px;
	position: absolute;
	left: -100%;
	padding: 20px;
	background: fade-out($white, 0.1);
	transition: left ease-in-out 300ms;
	border-radius: 3px;
	width: 650px;
	max-width: 100%;

	@media only screen and (max-width: 1024px) {
		top: 55px;
		padding: 20px;
		width: 650px;
		max-width: 100%;
	}

	&.show {
		left: 130px;
	}

	// Base styles for all 3 steps./
	section {
		margin-bottom: 15px;

		h2 {
			color: $white;
			padding: 2px 3px 0;
			margin-bottom: 5px;
			justify-content: space-between;
			position: relative;
			display: flex;
			z-index: 5;
			align-items: flex-end;

			&:after {
				border-top: 4px dotted $secondarycolor;
				width: calc( 100% - 190px);
				content: '';
				height: 4px;
				display: inline-block;
			}

			&:before {
				position: absolute;
				left: -3px;
				top: -3px;
				bottom: -3px;
				width: 68px;
				background-color: $black;
				content: '';
				z-index: -1;
			}

			&.result-title {
				&:before {
					width: 100px;
				}
			}
		}

		p {
			margin-bottom: 0;
		}
	}

	.step1 {
		position: relative;

		&:before {
			background-color: fade-out($secondarycolor, .8);
			position: absolute;
			left: 190px;
			top: 16px;
			bottom: -5px;
			width: calc(100% - 190px);
			content: '';
			display: block;
			z-index: 0;
			pointer-events: none;
		}


		div {
			display: flex;

			p {
				flex: 0 1 190px;
			}

			ul {
				display: flex;
				padding: 0 10px;
				flex-basis: calc(100% - 210px);
				align-items: center;

				li {
					display: flex;
					align-items: center;
					flex: 0 1 25%;

					label {
						position: relative;
						display: flex;
						align-items: center;
						flex: 1;
						&:after {
							margin-left: 10px;
							position: relative;
							background-color: fade-out($secondarycolor, .4);
							border: 1px solid $secondarycolor;
							content: '';
							width: 14px;
							height: 14px;
							border-radius: 100px;
							display: inline-block;
							font-weight: 600;
							color: #fff;
							font-size: 20px;
							line-height: 14px;
							text-align: center;
							transition: all ease-in-out .3s;
						}
					}

					input {
						display: none;

						&:checked + label {
							&:after {
								background-color: $secondarycolor;
								content: '+';
							}
						}

						&:disabled + label {
							pointer-events: none;
							opacity: .3;
						}
					}
				}
			}
		}
	}

	.step2 {

		ul {
			padding: 5px 0;
			position: relative;

			li {
				display: flex;
				position: relative;
				z-index: 10;


				label {
					flex: 0 1 190px;
					padding-right: 10px;
				}

				input {
					width: 90px;
				}
			}

			&:after {
				background-color: fade-out($secondarycolor, .8);
				position: absolute;
				left: 190px;
				top: 0;
				bottom: 0;
				width: 115px;
				content: '';
				display: block;
				z-index: 0;
				pointer-events: none;
			}
		}
	}


	.step3 {
		ul {
			padding: 5px 0;
			position: relative;

			&:before {
				background-color: fade-out($secondarycolor, .8);
				position: absolute;
				left: 190px;
				top: 0;
				bottom: 67px;
				width: 115px;
				content: '';
				display: block;
				z-index: 0;
				pointer-events: none;
			}

			&:after {
				background-color: fade-out($secondarycolor, .8);
				position: absolute;
				left: 190px;
				height: 52px;
				bottom: 0;
				width: 115px;
				content: '';
				display: block;
				z-index: 0;
				pointer-events: none;
			}

			li {
				display: flex;
				position: relative;
				z-index: 10;

				&.zonnepanelen {
					margin-top: 20px;
				}

				label:not(.aantal-panelen) {
					order: 1;
					position: relative;
					display: flex;
					width: 190px;
					align-items: center;
					padding-right: 10px;

					&:after {
						position: absolute;
						background-color: fade-out($secondarycolor, .4);
						border: 1px solid $secondarycolor;
						content: '';
						width: 14px;
						height: 14px;
						left: 200px;
						border-radius: 100px;
						display: inline-block;
						font-weight: 600;
						color: #fff;
						font-size: 20px;
						line-height: 14px;
						text-align: center;
						transition: all ease-in-out .3s;
					}
				}

				label.aantal-panelen {
					width: 190px;
					padding-right: 10px;
				}

				input[type=text] {
					&:read-only {
						color: $black;
						&:focus {
							background-color: transparent;
							padding: 0;
						}
					}
				}

				input[type=checkbox] {
					display: none;

					&:checked + label {
						&:after {
							background-color: $secondarycolor;
							content: '+';
						}
					}

					&:disabled + label {
						pointer-events: none;
						opacity: .3;
					}
				}
			}
		}
	}


	.result {
		position: relative;
		margin-bottom: 0;

		h2 {
			margin-bottom: 15px;
		}
		&:after {
			background-color: fade-out($secondarycolor, .8);
			position: absolute;
			left: 190px;
			top: 26px;
			bottom: -5px;
			width: 115px;
			content: '';
			display: block;
			z-index: 0;
			pointer-events: none;
		}

		ul {
			position: relative;

			li {
				display: flex;
				position: relative;
				z-index: 10;


				label {
					flex: 0 1 190px;
					padding-right: 10px;
				}

				input {
					width: 90px;
					&:read-only {
						color: $black;
					}

					&:focus {
						background-color: transparent;
						padding: 0;
					}

					&#energierekening_verschil {
						font-weight: 700;
					}
				}
			}


		}
	}
}