
.site-header {
	background: $primarycolor;
	text-align: center;
	padding: 10px 0;
	height: 52px;
	position: relative;

	&__logo {
		font-size: 36px;
		font-weight: 700;
		top: -10px;
		left: 50%;
		transform: translateX(-50%);
		position: absolute;
		color: $white;
		background-image: url(../img/logo_warmtetool.svg);
		background-size: contain;
		background-repeat: no-repeat;
		width: 140px;
		height: 185px;
		z-index: 2000;
		display: block;
		text-indent: -99999px;
		transition: all ease-in-out 250ms;

		&.active {
			background-image: url(../img/logo_warmtetool.svg);
			height: 400px;
			width: 300px;
			z-index: 4000;
		}

		@media only screen and (max-width: 1024px) {
			height: 155px;
			width: 115px;
		}

	}

	&:after {
		content: '';
		position: absolute;
		background-color: $white;
		height: 35px;
		display: block;
		left: 0;
		top: 72px;
		right: 0;
	}

	&:before {
		z-index: 2;
		content: '';
		position: absolute;
		border-bottom: 3px dotted $primarycolor;
		height: 0;
		display: block;
		left: 0;
		top: 72px;
		right: 0;
	}
}
