@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

$primarycolor: #026aa3;
$secondarycolor: #00919e;
$white: #fff;
$black: #000;

$red: #d2433f;
$orange: #f29e30;
$orange-dark: #b0821a;
$orange-light: #e7b26a;
$greenish: #9aa539;

$textcolor: #333;
$linkcolor: #1f6fd1;

$titlefont: 'Aller',  sans-serif;
$bodyfont: 'Aller', sans-serif;

