html {
	box-sizing: border-box;
	background-color: lighten($greenish, 5%);
	height: 100%;
	position: relative;

	&:before {
		position: fixed;
		z-index: 0;
		content: '';
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		background-size: cover;
		background-position: center left;
		background-image: url(../img/home-bg.jpg);
		background-repeat: no-repeat;
	}
}

::-moz-selection {
	background: $linkcolor;
	color: $white;
}

::selection {
	background: $linkcolor;
	color: $white;
}

body {
	font-family: $bodyfont;
	-webkit-font-smoothing: antialiased;
	line-height: 20px;
	font-weight: 400;
	font-size: 14px;
	min-height: 100%;
	overflow-x: hidden;
	z-index: 20;
	transition: opacity 250ms ease-in-out;

	@media only screen and (max-width: 1024px) {
		font-size: 13px;
	}

	&:before {
		position: fixed;
		z-index: 0;
		content: '';
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		background-size: cover;
		background-position: center left;
		background-image: url(../img/tool-bg.jpg);
		background-repeat: no-repeat;
	}

	&.active {
		overflow: hidden;

		&:before {
			opacity: 0;
		}

		&:after {
			content: '';
			z-index: 2500;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			opacity: 0.6;
			background-color: $textcolor;
		}
	}
}

.wrapper {
	margin-left: auto;
	margin-right: auto;
	max-width: 1440px;
	padding-left: 25px;
	padding-right: 25px;
	position: relative;
	transition: padding .2s ease-in-out;
}

img {
	display: inline-block;
	height: auto;
	max-width: 100%;
	@include border-radius(2px);

	&.alignleft {
		float: left;
	}

	&.aligncenter {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	&.alignright {
		float: right;
	}
}

figure {
	&.thumb {
		float: left;
		margin-right: 20px;
	}
}

.search-highlight {
	background-color: $linkcolor;
	color: $white;
}

.clearfix {
	clear: both;
}

.wp-caption {
	text-align: center;

	&.alignleft {
		float: left;
		margin-right: 30px;
	}

	&.aligncenter {
		margin-left: auto;
		margin-right: auto;
	}

	&.alignright {
		float: right;
		margin-left: 30px;
	}
}

.gallery {
	.gallery-item {
		.gallery-icon {
			margin-bottom: 10px;

			a img {
				border: none !important;
				opacity: 1;
				transition: all ease-in-out 125ms;

				&:hover {
					opacity: 0.9;
				}
			}
		}
	}
}

input {
	background-color: transparent;
	color: $red;
	font-weight: 400;
	border: none;
	width: 50px;
	padding: 0 6px 0 0;
	transition: all ease-in-out 250ms;

	&:focus {
		color: $orange-dark;
		background-color: $white;
		outline: none;
		padding: 0 0 0 6px;
		border: none;
	}
}